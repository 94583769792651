.product-tile {
    border: 1px solid #ddd;
    padding: 20px;
    margin: 20px;
    display: flex;
    align-items: center;
}

.product-tile img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-right: 20px;
    outline: 2px solid;
}

.product-info p {
    margin: 5px 0;
    text-align: left;
}

.product-info small {
    color: #888;
    font-size: small;
}